body,
html {
  background-color: #201f1e;
  width: 100%;
  height: 100%;
}

.app {
  background-color: "#201f1e";
  width: 100vh;
  height: 100vh;
}

.piece {
  /* height: 70%; */
  width: 70%;
}

.cardbackpiece {
  height: 45px;
  width: 45px;
  fill: gray;
  color: gray;
  filter: brightness(45%) contrast(50%);
}

.material-piece {
  width: 35px;
}

.material-advantage-piece {
  width: 20px;

  margin-left: -3px;
  margin-right: -3px;
  /* filter: hue-rotate(0deg) brightness(0%) saturate(100%) sepia(0%) invert(50%) opacity(100%); */
}

.material-advantage-piece svg *,
.material-advantage-piece svg {
  fill: gray !important;
  stroke: gray !important;
  /* opacity: 0.5; */
  /* filter: hue-rotate(0deg) brightness(0) saturate(100%); */
}

.material-advantage-piece-king svg *,
.material-advantage-piece-king svg {
  margin-left: -3px;
  margin-right: -3px;
  width: 35px;
  fill: rgb(241, 158, 48, 0.8) !important;
  stroke: rgb(241, 158, 48, 0.8) !important;
  /* opacity: 0.5; */
  /* filter: hue-rotate(0deg) brightness(0) saturate(100%); */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.star {
  animation: rotate 5s linear infinite;
  color: red;
}
